import { api } from "../Utils/constants";
import { setErrorStatus } from "../Redux/status/action";

export async function checkAccountAvailability(phoneNumber) {
  const response = await fetch(
    `${
      api.baseUrl
    }/profile/isExists?type=employee&phoneNumber=${encodeURIComponent(
      phoneNumber
    )}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
  const result = await response.json();
  if (result.error) {
    handleError(result.error);
  }
  return result;
}
export async function compareFace(locationData, accessToken) {
  const response = await fetch(`${api.baseUrl}/faceComparison`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-auth-token": `Bearer ${accessToken}`
    },
    body: locationData
  });
  const result = await response.json();
  if (result.error) {
    handleError(result.error);
  }
  return result;
}

function handleError(error) {
  if (error.name === "requestError") {
    setErrorStatus({ code: "custom", message: error.message });
  } else if (error.name === "authError") {
    setErrorStatus({
      code: "auth/id-token-expired",
      message: "Session expired. Please login"
    });
  } else {
    throw error;
  }
}
